import React from "react";


export default function CTA({ subHeader="",header="",link }) {

    return (
        <div className="bg-emerald-50 rounded-sm px-5 py-10 my-2 ">
            <div className="md:flex">
                <div>
                    <div className="font-extrabold text-3xl mb-1" >{header}</div>
                    <div className="text-lg font-light text-gray-700">{subHeader}</div>
                </div>
                <a href={link} className="w-full md:w-auto my-auto ml-auto" rel="nofollow" target="_blank"><button className="mt-5 md:mt-0 w-full md:w-auto bg-emerald-500 hover:bg-emerald-600 px-5 py-3 text-white font-bold text-lg" >Börja här</button></a>
            </div>
        </div>
    );
}
