import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import CTA from "../components/Cards/CTA";

export default function Studentguide({ data }) {
  const title = "Planera studentmottagning 2023 - Checklista och tips";
  return (
    <>
      <Page title={title}>
      <div className="py-3 bg-gray-50"><p className="flex max-w-screen-xl mx-auto items-center px-4 sm:px-8 justify-between w-full text-gray-700 text-sm md:text-base">Artikeln innehåller reklam genom annonslänkar.</p></div>
        <article className="container px-5 py-6 mx-auto info-content">
          <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} className="mb-5 rounded-xl" alt="studentmottagning" />
          <h1 className="text-4xl text-gray-900 font-bold md:text-4xl lg:text-5xl">
            {title}
          </h1>
          <p>Studentmottagningen är en stor del av studentfirandet. Men för att kunna skapa en oförglömlig studentfest så finns det en del saker som kan behövas förberedas och organiseras.</p>
          <p>I denna artikeln går vi därför igenom allt som du behöver veta inför studenten såsom vad man behöver tänka på, vilken mat man ska servera och vilka som man ska bjuda.</p>
          <h2 id="n-r-ska-man-b-rja-planera-inf-r-studenten-">När ska man börja planera inför studenten?</h2>
          <p>Det är bra att börja planera studentmottagning tidigare än vad man tror inför studenten. Börja fundera på vilka ni ska bjuda och vilken typ av mat och dryck du vill ha. Det är bra att börja göra detta redan 2-3 månader före evenemanget.</p>
          <p>Ju tidigare du börjar planera, desto mer tid kommer du också ha på dig att organisera och ordna allt. Detta gör att det blir mindre stressigt senare när den stora dagen närmar sig.</p>
          <p>Genom att börja planera tidigt så ser du också till att du får tillräckligt med tid att ordna med inbjudningskorten och vilka du ska bjuda på mottagningen.</p>
          <p>Normalt sätt brukar man skicka <a href="/skriva-pa-studentinbjudan/">inbjudningskort</a> ungefär 6 veckor innan det är dags för studenten. Det ger de inbjudna tiden att planera och svara på inbjudan så att du senare vet hur många som kommer.</p>
          <h2 id="vad-beh-ver-du-planera-f-r-studenten-">Vad behöver du planera för studenten?</h2>
          <p>Det finns flera saker du måste tänka över när du planerar din studentmottagning, till exempel tider, plats, vilka gäster ni ska bjuda, meny och budget.</p>
          <h3 id="tider">Tider</h3>
          <p>När du planerar så behöver ni fundera på när ni ska börja välkomna gästerna, när maten kommer att serveras och när kvällen kommer att avslutas. Det är bra att ha ett ungefärligt schema över hur kvällen kommer att se ut så att du kan planera därefter.</p>
          <p>Tiden för att starta en studentmottagningen beror lite på vilket upplägg du har och om ni exempelvis ska ha öppet hus, samt när studenterna kommer att springa ut.</p>
          <p>Om studenterna springer ut tidigt, så brukar man påbörja studentmottagningen tidigare också. Om de exempelvis springer ut klockan 11 för att senare åka hem kan man påbörja studentmottagningen omkring klockan 15.</p>
          <h3 id="plats">Plats</h3>
          <p>Platsen är också en viktig faktor att ta hänsyn till när ni planerar er studentmottagning. Om du vill hålla den liten och intim kan du välja att ha den hemma hos dig. Medan om ni är många som kommer att fira studenter kan det vara bäst att vara i en hyrd lokal.</p>
          <p>Du kan också överväga att fira utomhus om ni exempelvis hyr ett partytält som ni kan ställa på gräsmattan. Detta kan vara ett trevligt alternativ på sommaren.</p>
          <h3 id="g-ster">Gäster</h3>
          <p>När du planerar din studentmottagning måste du också tänka på vilka du ska bjuda in. Du kanske vill hålla den liten och bara bjuda in nära familj och vänner. Eller så kanske du vill inkludera alla du känner.</p>
          <p>Det är viktigt att tänka på hur många personer du vill ha eftersom det exempelvis kommer påverka vilken plats ni senare kan vara på och hur mycket studentmottagningen senare kommer att kosta för er.</p>
          <p>Det finns inga fasta regler för vem du måste bjuda in till studentmottagningen, men det är viktigt att du tänker på vem du vill ska vara där och fira med dig.</p>
          <h3 id="meny">Meny</h3>
          <p>Menyn är också en viktig del av en studentmottagning och bör planeras därefter. Du måste tänka på vilken typ av <a href="/mat-for-studenten/">mat och dryck</a> som du vill servera.</p>
          <p>När man funderar på meny är det bra att tänka på att det gärna ska vara enkelt så att det inte blir så stressigt, samt lätt och snabb att tillaga.</p>
          <p>Det är också viktigt att tänka på om det finns några kostrestriktioner bland gästerna. Om du till exempel bjuder in någon med allergi kan det vara bra att det finns något alternativ även för dem.</p>
          <GatsbyImage image={data.buffet.childImageSharp.gatsbyImageData} className="rounded-xl" alt="buffé" />
          <h3 id="budget">Budget</h3>
          <p>När du planerar en studentmottagning är det viktigt att tänka på hur mycket pengar du vill spendera. Detta påverkar till exempel vilken typ av mat och dryck ni kan servera och vilken lokal ni kan hyra.</p>
          <p>Budgeten kommer också till stor del avgöra hur många gäster som ni kan bjuda, och om ni håller studentmottagningen lite mindre kommer den att bli billigare.</p>
          <h2 id="vem-ska-man-bjuda-p-studenten-">Vem ska man bjuda på studenten?</h2>
          <p>Antalet personer som ni bestämmer att bjuda på studentmottagningen kommer att påverka storleken och känslan av festen. Traditionellt sett så brukar man bjuda familj, släktingar, vänner och nära grannar. Detta brukar motsvara ungefär 30 personer.</p>
          <p>Men om du önskar en mer mindre studentmottagning, så kan ni välja att endast bjuda den närmaste familj och släktingar. Detta brukar också leda till en mer intim och lugnare atmosfär, vilket kan vara rätt så skönt.</p>
          <p>En annan fördel med att hålla nere antalet personer som man bjuder är dessutom att man håller nere sina kostnader.</p>
          <p>Det viktigaste när du bestämmer vilka du ska bjuda är dock att du bjuder de personer du vill umgås med och dela studenten med. För det är det allra viktigaste.</p>
          <CTA header="Dags att fixa studentinbjudan?" subHeader="Designa och beställ dina inbjudningkort under 5 min." link="https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/produkter/designselection.aspx?pcg=cards.large&occ=student&purpose=invitation" />
          <h2 id="vilken-mat-ska-man-servera-p-studenten-">Vilken mat ska man servera på studenten?</h2>
          <p>På en studentmottagningen vill man gärna servera mat som man enkelt kan förbereda så att man behöver göra så lite som möjligt under studentdagen.</p>
          <p>Några vanliga rätter på studentmottagningen är:</p>
          <ul>
            <li><strong>Matpajer</strong></li>
            <li><strong>Ostar</strong></li>
            <li><strong>Pastasallad</strong></li>
            <li><strong>Färsk frukt</strong></li>
            <li><strong>Sallader</strong></li>
            <li><strong>Olika röror</strong></li>
            <li><strong>Bröd</strong></li>
            <li><strong>Smörgåstårta</strong></li>
            <li><strong>Lax</strong></li>
            <li><strong>Marinerat kött</strong></li>
          </ul>
          <p>Detta är bara några exempel på mat som kan serveras, och det finns många fler alternativ. Det viktiga är att du väljer något som du känner dig säker på att laga och som du vet att dina gäster kommer att gilla.</p>
          <GatsbyImage image={data.dryck.childImageSharp.gatsbyImageData} className="rounded-xl" alt="dryck på studentmottagning" />
          <h2 id="vilken-dryck-ska-man-servera-p-studenten-">Vilken dryck ska man servera på studenten?</h2>
          <p>Drycken som serverar kommer att påverka stämningen och känslan av festen, och traditionellt sett så brukar man servera champagne, vin, öl och alkoholfria alternativ som läsk och vatten på en studentmottagning.</p>
          <p>Dessa sammanställs nedan:</p>
          <ul>
            <li><strong>Läsk</strong></li>
            <li><strong>Öl</strong></li>
            <li><strong>Vin</strong></li>
            <li><strong>Champagne</strong></li>
            <li><strong>Kaffe</strong></li>
            <li><strong>Te</strong></li>
          </ul>
          <p>För att hålla nere kostnaden på drycker så kan det vara smart att exempelvis köpa läsk och öl i bulk. Många väljer till och med att åka till Danmark eller Tyskland för att kunna köpa billigare alkohol.</p>
          <h2 id="vad-brukar-man-g-ra-p-studentmottagning-">Vad brukar man göra på studentmottagning?</h2>
          <p>En studentmottagning brukar att innebära mat, presenter till den som tagit studenten och en chans att umgås och ha roligt. Många studenter innehåller också lekar och aktiviteter för att skapa en rolig och minnesvärd fest.</p>
          <p>Ett vanligt upplägg för studenten är:</p>
          <ul>
            <li><strong>17:00-18:00</strong> - Folket anländer och får välkomstdrink.</li>
            <li><strong>18:00-19:00</strong> - Middag serveras och eventuellt håller något ett tal.</li>
            <li><strong>19:00-20:00</strong> - Presentöppning och socialt umgänge.</li>
            <li><strong>20:00-21:00</strong> - Efterrätten serveras.</li>
            <li><strong>21:00-22:00</strong> Socialt umgänge eller andra aktiviteter.</li>
            <li><strong>22:00-23:00</strong> - Avslutning och ta farväl till gästerna.</li>
          </ul>
          <p>Detta är ett förslag på hur en student kan se ut, samtidigt så är det självklart upp till varje värd att själv bestämma hur de vill att sin student ska se ut.</p>
          <p>När du planerar studenten så är det också bra att vara medveten om att folk kan komma och gå litegrann. Alla kommer förmodligen inte att delta under hela mottagningen.</p>
          <h2 id="checklistan-f-r-studentmottagningen-2023-">Checklistan för studentmottagningen (2023)</h2>
          <p>Nedan delar vi med oss av vår kompletta checklista på allt som du behöver ordna inför studentmottagningen.</p>
          <h3 id="mat-och-dryck-">Mat och dryck:</h3>
          <ul>
            <li><strong>Mat</strong></li>
            <li><strong>Dryck (För de vuxna samt alkoholfritt)</strong></li>
            <li><strong>Kaffe</strong></li>
            <li><strong>Mjölk (till kaffet)</strong></li>
            <li><strong>Efterrätt</strong></li>
            <li><strong>Snacks (chips, popcorn eller kanske godis?)</strong></li>
            <li><strong>Välkomstdrink</strong></li>
            <li><strong>Mindre tilltugg till välkomstdrinken</strong></li>
          </ul>
          <h3 id="grunder-f-r-studentmottagningen-">Grunder för studentmottagningen:</h3>
          <ul>
            <li><strong>Partytält (om ni inte får plats inne)</strong></li>
            <li><strong>Bord och stolar</strong></li>
            <li><strong>Presentbord</strong></li>
          </ul>
          <h3 id="dekoration-">Dekoration:</h3>
          <ul>
            <li><strong>Girlanger</strong></li>
            <li><strong>Flaggor till borden</strong></li>
            <li><strong>Ljusslinga</strong></li>
            <li><strong>Dukar</strong></li>
          </ul>
          <p>Om du funderar på att köpa dekorationer så har Partykungen ett stort utbud av <a href="https://click.adrecord.com/?c=155221&p=198&url=https://www.partykungen.se/studentdekorationer/" rel="nofollow" target="_blank">studentdekorationer</a>.
          </p>
          <h3 id="eng-ngsartiklar-">Engångsartiklar:</h3>
          <ul>
            <li><strong>Plastglas</strong></li>
            <li><strong>Plastbestick</strong></li>
            <li><strong>Plasttallrikar</strong></li>
            <li><strong>Kaffemuggar</strong></li>
            <li><strong>Servetter</strong></li>
          </ul>
          <h3 id="-vrigt-till-studentmottagningen-">Övrigt till studentmottagningen:</h3>
          <ul>
            <li><strong>Högtalare</strong></li>
            <li><strong>Element (om ni ska vara ute)</strong></li>
            <li><strong>Frysbox (för det som ej får plats i kylen)</strong></li>
            <li><strong>Hängare för jackor</strong></li>
          </ul>
          <h2>Vanliga frågor om studenten</h2>
          <p>Nedan går vi igenom några av de vanligaste frågorna som brukar komma upp när man planerar sin studentmottagning.</p>
          <h3>Hur mycket kostar det att anordna en studentmottagning?</h3>
          <p>Kostnaden för en studentmottagning varierar beroende på olika faktorer som antalet gäster, meny, plats och eventuell dekor. Generellt sätt så kan man räkna med en kostnad på omkring 150-250 kronor per person för mottagningen. Tänk därför på att kostnaden ökar med antalet inbjudna och den typen av mat och dryck du väljer.</p>
          <p>För att hålla nere kostnaderna kan du överväga att servera enklare mat, köpa dryck i bulk och hyra utrustning istället för att köpa allt nytt. Det är viktigt att sätta en budget i förväg och anpassa dina planer därefter för att undvika överraskande utgifter.</p>
          <h3>Hur lång tid brukar en studentmottagning pågå?</h3>
          <p>Längden på en studentmottagning kan variera beroende på dina önskemål och planer. Generellt sett brukar en studentmottagning vara mellan 3-6 timmar, inklusive tid för gästerna att anlända, äta, umgås och eventuellt delta i lekar eller aktiviteter.</p>
          <p>Om du vill så kan du alltid ange en start- och sluttid på inbjudan för att ge gästerna en uppfattning om hur länge mottagningen förväntas pågå.</p>
          <h3>Ska man ha lekar och aktiviteter under studentmottagningen?</h3>
          <p>Det är helt upp till dig om du vill inkludera lekar och aktiviteter under studentmottagningen. Vissa personer föredrar en mer avslappnad och social atmosfär, medan andra tycker att lekar och aktiviteter kan göra festen roligare och mer minnesvärd.</p>
          <p>Om du väljer att ha <a href="/studentlekar/">lekar och aktiviteter</a>, se till att de passar åldersgruppen och intressena hos dina gäster.</p>
          <h3>Vad kan man göra om vädret är dåligt under studentmottagningen?</h3>
          <p>Om vädret är dåligt under studentmottagningen finns det flera alternativ att överväga. Om du har planerat att hålla mottagningen utomhus, kan du hyra ett partytält eller ordna en plats inomhus som en backup-plan.</p>
          <p>Om vädret ser ut att bli bättre senare kan du ibland också justera tiden för mottagningen om vädret förväntas förbättras senare under dagen, men glöm inte att informera dina gäster om eventuella ändringar i planerna.</p>
          <h3>Hur tar man hänsyn till gästernas allergier och kostpreferenser?</h3>
          <p>När du planerar menyn för din studentmottagning är det viktigt att ta hänsyn till gästernas allergier, kostrestriktioner och preferenser. Du kan göra detta genom att inkludera ett någon maträtt som passar för gästernas kosthållningar, såsom vegetarisk, vegansk, glutenfri och laktosfri.</p>
          <p>Se till att erbjuda alternativ för personer med allergier, till exempel nöt- och skaldjursfria rätter. Du kan också fråga dina gäster om deras kostpreferenser och allergier när du skickar ut inbjudningar, så att du vet vad som behöver beaktas vid planeringen.</p>
          <p>Tänk också på att märka maten tydligt så att gästerna vet vad som är säkert för dem att äta.</p>
          <CTA header="Köpt allt inför studentfesten?" subHeader="Skapa en minnesvärd fest vackra dekorationer och tillbehör." link="https://click.adrecord.com/?c=155221&p=665&url=https://www.partyhallen.se/studenten" />
        
        </article>
      </Page>
    </>
  );
}


export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "studentguide.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    dryck: file(relativePath: { eq: "glasses.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buffet: file(relativePath: { eq: "buffet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
